import WholeCard from "./components/WholeCard";

function App() {
  return (
    <div className="bg-white">
      <WholeCard />
    </div>
  );
}

export default App;
